import { CODING_MODE, ITEM_TYPE, VALIDATION_FLAG } from './../config'

/**
 * For Category and Brands in query builder we have some extended search options
 * like "contains", "starts with" etc. When we request data from the server
 * we need to limit the number of results due to performance reasons.
 */
/* eslint-disable import/prefer-default-export */
export const LIMIT_EXTENDED_SEARCH_RESULTS = 500

/**
 * This is special constant for NIQ search page
 * I copied this from the /constant/constants.js.
 * NOTE: It can contain some not used values in NIQ search page. Pls remove it if you find any.
 */
const constants = {
  fields: {
    merchant_name: {
      label: 'Merchant',
      shortLabel: 'M'
    },
    brand_name: {
      label: 'Brand',
      shortLabel: 'B',
      canBeParent: true
    },
    category_full_path: {
      label: 'Category',
      shortLabel: 'C',
      canBeParent: true
    },
    description_first_word: {
      label: 'First Word',
      shortLabel: 'FW'
    },
    description: {
      label: 'Description',
      shortLabel: 'D'
    },
    revenue: {
      label: 'Revenue',
      shortLabel: 'R'
    },
    item_count: {
      label: 'Item Count',
      shortLabel: 'R'
    },
    last_revenue: {
      label: 'Last Revenue',
      shortLabel: 'LR'
    },
    factor: {
      label: 'Amazon Factor',
      shortLabel: 'F'
    },
    user_unique_weight: {
      label: 'User Weight',
      shortLabel: 'UW'
    },
    quantity: {
      label: 'Quantity',
      shortLabel: 'Q'
    },
    price: {
      label: 'Price',
      shortLabel: 'P'
    },
    units: {
      label: 'Units',
      shortLabel: 'U'
    },
    user_id: {
      label: 'User ID [Approx Count]',
      shortLabel: 'ID'
    },
    revenue_diff: {
      label: 'Revenue Diff'
    },
    other: {
      label: 'Other',
      shortLabel: 'O'
    },
    subject: {
      label: 'Subject',
      shortLabel: 'S'
    },
    template: {
      label: 'Template',
      shortLabel: 'TMPL'
    },
    from_addr: {
      label: 'From Address',
      shortLabel: 'FA'
    },
    domain: {
      label: 'Domain',
      shortLabel: 'DM'
    },
    email_type: {
      label: 'Email Type',
      shortLabel: 'ET'
    },
    bbx_supra: {
      label: 'BBX Supra',
      shortLabel: 'BBXS'
    },
    is_cfg: {
      label: 'IS CFG',
      shortLabel: 'CFG'
    },
    source: {
      label: 'Source',
      shortLabel: 'SRC'
    },
    distinct_msg_uuid: {
      label: '# Emails',
      shortLabel: 'NE'
    },
    distinct_mailbox_uuid: {
      label: '# Mailboxes',
      shortLabel: 'NM'
    },
    cnt_msg: {
      label: '# Messages',
      shortLabel: 'NMSG'
    },
    percentage_msg: {
      label: '% Messages',
      shortLabel: 'PM'
    },
    cnt_mailbox: {
      label: '# Mailboxes',
      shortLabel: 'NM2'
    },
    percentage_msg_total_cusum: {
      label: '% Messages Cusum',
      shortLabel: 'PMC'
    },
    //
    // NIQ specific fields  IN-1662
    slice_brand_name: {
      label: 'Slice Brand',
      shortLabel: 'SB',
      canBeParent: true
    },
    slice_category_full_path: {
      label: 'Slice Category',
      shortLabel: 'SC',
      canBeParent: true
    },

    invalid_category: {
      label: 'Invalid Category',
      shortLabel: 'IC'
    },
    invalid_brand: {
      label: 'Invalid Brand',
      shortLabel: 'IB'
    },

    nankey: {
      label: 'Nankey',
      shortLabel: 'NK'
    },

    [ITEM_TYPE]: {
      label: 'Item Type',
      shortLabel: 'IT'
    },

    sales: {
      label: 'Sales',
      shortLabel: 'S'
    },

    coding_mode: {
      label: 'Coding Mode',
      shortLabel: 'CM'
    },

    segment_confidence: {
      label: 'Segment Confidence',
      shortLabel: 'SCnf'
    },

    module_confidence: {
      label: 'Module Confidence',
      shortLabel: 'MCnf'
    },

    brand_confidence: {
      label: 'Brand Confidence',
      shortLabel: 'BCnf'
    },

    subcategory_confidence: {
      label: 'SubCategory Confidence',
      shortLabel: 'SubCCnf'
    },

    omnisales_module: {
      label: 'Omnisales Module',
      shortLabel: 'OmnM'
    },

    brand_owner: {
      label: 'Brand Owner',
      shortLabel: 'BO'
    }
  },
  esFields: {
    merchant: 'merchant_name',
    brand: 'brand_name',
    last_brand: 'last_brand_name',
    last_category: 'last_category_full_path',
    last_merchant: 'merchant_name',
    category: 'category_full_path',
    revenue: 'revenue',
    last_revenue: 'last_revenue',
    item_count: 'revenue',
    last_item_count: 'last_revenue',
    weight: 'user_unique_weight',
    last_weight: 'last_user_unique_weight',
    factor: 'factor',
    last_factor: 'last_factor',
    last_user_id: 'user_id',
    description_first_word: 'description_first_word',
    quantity: 'quantity',
    price: 'price',
    none: 'none',
    last_none: 'last_none',
    subject: 'subject',
    from_addr: 'from_addr',
    domain: 'domain',
    email_type: 'email_type',
    is_cfg: 'is_cfg',
    source: 'source',
    distinct_msg_uuid: 'distinct_msg_uuid',
    distinct_mailbox_uuid: 'distinct_mailbox_uuid',
    template: 'template',
    cnt_msg: 'cnt_msg',
    percentage_msg: 'percentage_msg',
    cnt_mailbox: 'cnt_mailbox',
    percentage_msg_total_cusum: 'percentage_msg_total_cusum',
    bbx_supra: 'bbx_supra',
    //
    // NIQ specific fields  IN-1662
    //
    slice_category: 'slice_category_full_path',
    slice_brand: 'slice_brand_name',

    invalid_category: 'invalid_category',
    invalid_brand: 'invalid_brand',

    nankey: 'nankey',
    rct_code: 'rct_code',
    [VALIDATION_FLAG]: VALIDATION_FLAG,

    sales: 'sales',
    [CODING_MODE]: CODING_MODE,
    subcategory_confidence: 'subcategory_confidence',
    segment_confidence: 'segment_confidence',
    module_confidence: 'module_confidence',
    brand_confidence: 'brand_confidence',
    omnisales_module: 'omnisales_module'
    // /NIQ specific fields
  },
  aggregations: {
    TIME: 'time',
    LAST_TIME: 'last_time',
    REVENUE: 'revenue',
    LAST_REVENUE: 'last_revenue',
    WEIGHT: 'user_unique_weight',
    LAST_WEIGHT: 'last_user_unique_weight',
    FACTOR: 'factor',
    LAST_FACTOR: 'last_factor',
    QUANTITY: 'quantity',
    LAST_QUANTITY: 'last_quantity',
    PRICE: 'price',
    LAST_PRICE: 'last_price',
    UNITS: 'units',
    LAST_UNITS: 'last_units',
    USER_ID: 'user_id',
    LAST_USER_ID: 'last_user_id',
    ITEM_COUNT: 'item_count',
    LAST_ITEM_COUNT: 'last_item_count',
    DESCRIPTION: 'description',
    BRAND: 'brand',
    MERCHANT: 'merchant',
    CATEGORY: 'category',
    STATS: 'stats',
    NONE: 'none',
    DESCRIPTION_WITH_HISTORY: 'descriptionWithHistory',
    DESCRIPTION_FIRST_WORD: 'description_first_word',
    CONTEXT_REVENUE: 'context_revenue',
    CONTEXT_OTHER_REVENUE: 'context_other_revenue',
    CONTEXT_DESCRIPTION_COUNT: 'context_description_count',
    QUERIED_DESCRIPTION_COUNT: 'queried_description_count',
    QUERIED_REVENUE: 'queried_revenue',
    QUERIED_OTHER_REVENUE: 'queried_other_revenue',
    DICTIONARY_COVERAGE: 'dictionary_coverage',
    QC_MONITORING_INCLUDE: 'qc_monitoring_include',
    QC_MONITORING_EXCLUDE: 'qc_monitoring_exclude',
    //
    // NIQ specific fields
    //
    SLICE_BRAND: 'slice_brand',
    SLICE_CATEGORY: 'slice_category',

    INVALID_CATEGORY: 'invalid_category',
    INVALID_BRAND: 'invalid_brand',
    OMNISALES_MODULE: 'omnisales_module'
    // /NIQ specific fields
  },
  aggregationSuffix: ' (Last)',
  parentElementPrefix: 'Select All: ',
  searchConditions: {
    CONTAINS: 'contains',
    STARTS_WITH: 'startsWith'
  },
  itemTypes: {
    BOTH_ITEM: 'Both',
    ONLY_CURRENT: 'Only Current',
    ONLY_PREVIOUS: 'Only Previous',
    NEW_ITEM: 'New',
    DELETED_ITEM: 'Deleted',
    NON_ELIGIBLE_ITEM: 'No longer Eligible'
  },
  fieldNames: {
    CATEGORY: 'category_full_path',
    BRAND: 'brand_name',
    MERCHANT: 'merchant_name'
  },
  aggGroups: {
    DRILL_DOWN_CHART: 'drillDownChart',
    SPLIT_BY_CHART: 'splitByChart',
    DRILL_DOWN_OPTIONS: 'drillDownOptions',
    DESCRIPTION_WITH_HISTORY: 'descriptionWithHistory'
  },
  timeInterval: {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    QUARTER: 'quarter'
  },
  /* chartOptions: {
    absolute: {
      color: {
        current: '#00b0bf',
        last: '#ff564a'
      }
    },
    delta: {
      color: 'green'
    }
  }, */
  dateFormat: {
    qcTool: 'DD-MMM-YYYY',
    refreshData: 'DD MMM, YYYY h:mm A',
    reportDate: 'DD MMM, YYYY h:mm A'
  }
}

export const aggregationSettings = {
  [constants.aggregations.INVALID_CATEGORY]: {
    aggregationField: constants.esFields.category,
    term: { fieldName: constants.esFields.invalid_category, values: [true] }
  },
  [constants.aggregations.INVALID_BRAND]: {
    aggregationField: constants.esFields.brand,
    term: { fieldName: constants.esFields.invalid_brand, values: [true] }
  }
}
export default constants

export const validEmailTypes = ['ORDER', 'SHIPPING', 'WELCOME', 'PAYMENT', 'RETURNS', 'CANCELLATION', 'NOT_A_RECEIPT']

export const validSources = ['conflict', 'supra', 'staging', 'dictionary']

export const searchToolTabs = {
  EDIT_MODE: 'Analysis',
  TRENDS: 'Trends' // TODO: we don't have this tab for NIQ search page right now
}
